import _ from 'lodash';
import axios from 'axios';
import React, { useState } from 'react';
import { navigate } from 'gatsby';

import styles from './sendMessage.module.scss';

const SendMessage = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [messageStatus, setMessageStatus] = useState(null);

  const handleFieldInput = (action, errorAction, value) => {
    action(value);

    if (messageError || !_.isNull(messageStatus)) {
      setMessageError(false);
      setMessageStatus(null);
    }

    if (!_.isNil(errorAction)) {
      errorAction(false);
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (_.size(_.trim(name)) < 1) {
      setNameError(true);
      isValid = false;
    }

    if (_.size(_.trim(email)) < 1) {
      setEmailError(true);
      isValid = false;
    }

    if (_.size(_.trim(phone)) < 1) {
      setPhoneError(true);
      isValid = false;
    }

    if (_.size(_.trim(message)) < 1) {
      setMessageError(true);
      isValid = false;
    }

    // Validate phone number
    if (!phoneError) {
      const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (!phoneRegex.test(phone)) {
        setPhoneError(true);
        isValid = false;
      }
    }

    // Validate actual email address
    if (!emailError) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(_.trim(email))) {
        setEmailError(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setSubject('');
    setMessage('');
  };

  const onSubmit = async () => {
    const isValid = validateFields();
    if (!isValid) {
      return false;
    }
    setMessageStatus('In Progress');
    const messageData = {
      name: _.trim(name),
      email: _.trim(email),
      phone,
      subject,
      message,
    };
    try {
      const result = await axios.post('/.netlify/functions/message', messageData);
      if (_.get(result, 'data.success')) {
        setMessageStatus('Done');
        navigate('/thank-you', {
          replace: true,
        });
      }
      if (!_.get(result, 'data.success')) {
        setMessageStatus('Error');
      }
      clearForm();
    } catch (error) {
      setMessageStatus('Error');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Leave a Message</div>
      <div className={styles.instructions}>
        Call (801) 427-1054 or fill out the form below (if you live in Utah or outside the United
        States), and we will get back to you as soon as&nbsp;we&nbsp;can.
      </div>

      <div className={styles.note}>
        If you are a current client, please send us a message through our&nbsp;
        <a
          href="https://mindsetfamilytherapy.clientsecure.me/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Client Portal&nbsp;here.
        </a>
      </div>
      <div className={styles.formContainer}>
        <input
          type="text"
          placeholder="Name*"
          onChange={e => handleFieldInput(setName, setNameError, e.target.value)}
          value={name}
          className={nameError ? styles.errorBorder : styles.validBorder}
        />

        <input
          type="text"
          placeholder="Email*"
          onChange={e => handleFieldInput(setEmail, setEmailError, e.target.value)}
          value={email}
          className={emailError ? styles.errorBorder : styles.validBorder}
        />

        <input
          type="text"
          placeholder="Phone* 555-555-5555"
          onChange={e => handleFieldInput(setPhone, setPhoneError, e.target.value)}
          value={phone}
          className={phoneError ? styles.errorBorder : styles.validBorder}
        />

        <input
          type="text"
          placeholder="Subject"
          onChange={e => handleFieldInput(setSubject, null, e.target.value)}
          value={subject}
          className={styles.subjectInput}
        />

        <textarea
          placeholder="Message*"
          onChange={e => handleFieldInput(setMessage, setMessageError, e.target.value)}
          value={message}
          className={messageError ? styles.errorBorder : styles.validBorder}
        />
      </div>

      <button type="button" className={styles.submitButton} onClick={onSubmit}>
        SUBMIT
      </button>

      <div className={styles.messagingContainer}>
        {messageStatus === 'In Progress' && (
          <div className={styles.loading}>Sending message...</div>
        )}
        {messageStatus === 'Error' && <div className={styles.error}>Submit Error</div>}
      </div>
    </div>
  );
};

export default SendMessage;
